import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0" }
const _hoisted_3 = {
  class: "form",
  action: "#",
  id: "kt_subscriptions_create_new"
}
const _hoisted_4 = { class: "flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Customer = _resolveComponent("Customer")!
  const _component_Products = _resolveComponent("Products")!
  const _component_PaymentMethod = _resolveComponent("PaymentMethod")!
  const _component_Summary = _resolveComponent("Summary")!
  const _component_NewCardModal = _resolveComponent("NewCardModal")!
  const _component_CreateAccountModal = _resolveComponent("CreateAccountModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", _hoisted_3, [
          _createVNode(_component_Customer),
          _createVNode(_component_Products),
          _createVNode(_component_PaymentMethod)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Summary)
      ])
    ]),
    _createVNode(_component_NewCardModal),
    _createVNode(_component_CreateAccountModal)
  ], 64))
}