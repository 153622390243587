import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-lg-10" }
const _hoisted_2 = { class: "card-body pt-0" }
const _hoisted_3 = { class: "mb-7 d-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Notice = _resolveComponent("Notice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", { class: "fw-bolder" }, "Customer")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"text-gray-500 fw-bold fs-5 mb-5\"> Select or add a customer to a subscription: </div><div class=\"d-flex align-items-center p-3 mb-2\"><div class=\"symbol symbol-60px symbol-circle me-3\"><img alt=\"Pic\" src=\"media/avatars/300-5.jpg\"></div><div class=\"d-flex flex-column\"><a href=\"#\" class=\"fs-4 fw-bolder text-gray-900 text-hover-primary me-2\">Sean Bean</a><a href=\"#\" class=\"fw-bold text-gray-600 text-hover-primary\">sean@dellito.com</a></div></div>", 2)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("a", {
          href: "#",
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_customer_search"
        }, "Select Customer", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bolder text-gray-500 mx-2" }, "or", -1)),
        _createVNode(_component_router_link, {
          to: "/customers/list",
          class: "btn btn-light-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_users_search"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Add New Customer")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-10" }, [
        _createElementVNode("a", {
          href: "#",
          class: "btn btn-light-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_customer_search"
        }, "Change Customer")
      ], -1)),
      _createVNode(_component_Notice, {
        classes: "rounded-3",
        color: "primary",
        title: "This is a very important privacy notice!",
        body: _ctx.body
      }, null, 8, ["body"])
    ])
  ]))
}