import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card card-flush pt-3 mb-0",
  "data-kt-sticky": "true",
  "data-kt-sticky-name": "subscription-summary",
  "data-kt-sticky-offset": "{default: false, lg: '200px'}",
  "data-kt-sticky-width": "{lg: '250px', xl: '300px'}",
  "data-kt-sticky-left": "auto",
  "data-kt-sticky-top": "150px",
  "data-kt-sticky-animation": "false",
  "data-kt-sticky-zindex": "95"
}
const _hoisted_2 = { class: "card-body pt-0 fs-6" }
const _hoisted_3 = { class: "mb-7" }
const _hoisted_4 = { class: "d-flex align-items-center mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", null, "Summary")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "mb-3" }, "Customer details", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/subscriptions/view-subscription",
            class: "fw-bolder text-gray-800 text-hover-primary me-2"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Sean Bean ")
            ])),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "badge badge-light-success" }, "Active", -1))
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("a", {
          href: "#",
          class: "fw-bold text-gray-600 text-hover-primary"
        }, "sean@dellito.com", -1))
      ]),
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"separator separator-dashed mb-7\"></div><div class=\"mb-7\"><h5 class=\"mb-3\">Product details</h5><div class=\"mb-0\"><span class=\"badge badge-light-info me-2\">Basic Bundle</span><span class=\"fw-bold text-gray-600\">$149.99 / Year</span></div></div><div class=\"separator separator-dashed mb-7\"></div><div class=\"mb-10\"><h5 class=\"mb-3\">Payment Details</h5><div class=\"mb-0\"><div class=\"fw-bold text-gray-600 d-flex align-items-center\"> Mastercard <img src=\"media/svg/card-logos/mastercard.svg\" class=\"w-35px ms-2\" alt=\"\"></div><div class=\"fw-bold text-gray-600\">Expires Dec 2024</div></div></div><div class=\"mb-0\"><button type=\"submit\" class=\"btn btn-primary\" id=\"kt_subscriptions_create_button\"><span class=\"indicator-label\">Create Subscription</span><span class=\"indicator-progress\">Please wait... <span class=\"spinner-border spinner-border-sm align-middle ms-2\"></span></span></button></div>", 5))
    ])
  ]))
}