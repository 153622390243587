import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-lg-10" }
const _hoisted_2 = { class: "card-body pt-0" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = {
  class: "table align-middle table-row-dashed fs-6 fw-bold gy-4",
  id: "kt_subscription_products_table"
}
const _hoisted_5 = { class: "text-gray-600" }
const _hoisted_6 = { class: "odd" }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = {
  href: "#",
  class: "btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3",
  "data-bs-toggle": "tooltip",
  title: "",
  "data-kt-action": "product_remove",
  "data-bs-original-title": "Delete"
}
const _hoisted_9 = { class: "svg-icon svg-icon-3" }
const _hoisted_10 = { class: "even" }
const _hoisted_11 = { class: "text-end" }
const _hoisted_12 = {
  href: "#",
  class: "btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3",
  "data-bs-toggle": "tooltip",
  title: "",
  "data-kt-action": "product_remove",
  "data-bs-original-title": "Delete"
}
const _hoisted_13 = { class: "svg-icon svg-icon-3" }
const _hoisted_14 = { class: "odd" }
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = {
  href: "#",
  class: "btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3",
  "data-bs-toggle": "tooltip",
  title: "",
  "data-kt-action": "product_remove",
  "data-bs-original-title": "Delete"
}
const _hoisted_17 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"card-header\"><div class=\"card-title\"><h2 class=\"fw-bolder\">Products</h2></div><div class=\"card-toolbar\"><button type=\"button\" class=\"btn btn-light-primary\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_add_product\"> Add Product </button></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("table", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "text-start text-muted fw-bolder fs-7 text-uppercase gs-0" }, [
              _createElementVNode("th", { class: "min-w-300px" }, "Product"),
              _createElementVNode("th", { class: "min-w-100px" }, "Qty"),
              _createElementVNode("th", { class: "min-w-150px" }, "Total"),
              _createElementVNode("th", { class: "min-w-70px text-end" }, "Remove")
            ])
          ], -1)),
          _createElementVNode("tbody", _hoisted_5, [
            _createElementVNode("tr", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("td", null, "Beginner Plan", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("td", null, "1", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("td", null, "149.99 / Month", -1)),
              _createElementVNode("td", _hoisted_7, [
                _createElementVNode("a", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("tr", _hoisted_10, [
              _cache[3] || (_cache[3] = _createElementVNode("td", null, "Pro Plan", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("td", null, "1", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("td", null, "499.99 / Month", -1)),
              _createElementVNode("td", _hoisted_11, [
                _createElementVNode("a", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                  ])
                ])
              ])
            ]),
            _createElementVNode("tr", _hoisted_14, [
              _cache[6] || (_cache[6] = _createElementVNode("td", null, "Team Plan", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("td", null, "1", -1)),
              _cache[8] || (_cache[8] = _createElementVNode("td", null, "999.99 / Month", -1)),
              _createElementVNode("td", _hoisted_15, [
                _createElementVNode("a", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}